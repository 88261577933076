@import "../../../../sass/abstracts/mixins";

.part-1-3 {
  width: 50%;
  background-image: url("../../../../images/Auth/congrats.jpeg");
  background-size: cover;
  height: auto;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  font-family: "Baloo Da 2", sans-serif;
  background-position: center;

  @include breakpoint(mobileonly) {
    display: none;
  }

  @include breakpoint(phablet) {
    display: none;
    height: auto;
  }
}

.part-2-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 4rem;
  width: 50%;
  overflow-y: auto;

  @include breakpoint(mobileonly) {
    width: 100%;
    padding: 2rem;
    overflow: auto;
  }

  @include breakpoint(phablet) {
    width: 100%;
    padding: 8rem 2rem;
  }

  .title {
    font-family: "WickedGrit";
    font-size: 3rem;
    color: var(--vivid-green);

    @include breakpoint(mobileonly) {
      font-size: 2rem;
    }

    @include breakpoint(phablet) {
      font-size: 2rem;
    }
  }

  .sub-title {
    font-family: "Baloo Da 2";
    font-size: 1.6rem;
    color: var(--vivid-green);
    @include breakpoint(mobileonly) {
      font-size: 1.4rem;
    }

    @include breakpoint(phablet) {
      font-size: 1.4rem;
    }
  }

  .description-cont {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    text-align: left;
    .header-1 {
      font-size: 2.4rem;
      font-weight: bold;
      color: var(--text-secondary);
      margin-bottom: 1rem;
      font-family: "Baloo Da 2";
    }

    .desc {
      font-family: "Baloo Da 2";
      font-size: 1.6rem;
      color: var(--text-secondary);
      width: 100%;
    }
  }

  .timer-cont {
    margin: 0;
    font-size: 1.2rem;
    font-family: "Baloo Da 2";
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    margin-top: 3rem;
    width: 100%;
    color: var(--text-secondary);
    flex-wrap: wrap;

    .time {
      margin: 0 0.5rem;
      color: var(--highlight-primary) !important;
      font-weight: bold;
    }
  }
}
