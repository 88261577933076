.dashboard {
  font-family: 'Baloo Da 2';

  &__heading {
    margin-bottom: 2rem;
    font-size: 2.4rem;
    font-weight: 600;
  }

  .sub-headers {
    font-size: 1.4rem;
    margin: 2rem 0;
  }

  .dashboard-content {
    &__stats {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
    }

    &__chart {
      &--heading {
        margin-bottom: 2rem;
        margin-top: 2rem;
        font-size: 2.4rem;
        font-weight: 600;
      }

      &--view {
        display: flex;
        justify-content: space-between;
      }

      &--display {
        width: 45rem;
        min-height: 30rem;
      }
    }
  }
}

.stat-card {
  width: 20rem;
  height: auto;
  box-shadow: 1px 1px 14px rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;
  padding: 2rem;
  margin-bottom: 2rem;

  &__heading {
    font-size: 1.4rem;
    font-weight: 500;
  }

  &__count {
    font-size: 3rem;
    font-weight: 800;
  }
}
