@import '../../../sass/abstracts/mixins';

.v-create {
  padding-top: 6.5rem;
  color: var(--text-primary);
  font-family: 'Baloo Da 2', 'sans-serif';
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @include largeScreen();

  .upload-pic {
    font-family: 'Baloo Da 2';
    font-size: 1.2rem;
    color: var(--text-secondary);
    cursor: pointer;

    .img {
      height: 1.4rem;
      margin-right: 1rem;
    }
  }

  .banner-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30rem;
    border-radius: 10px;
    border: solid 2px #e1e2ea;
    width: 80%;

    .img-cont {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      position: relative;
      border-radius: 10px;
    }

    .middle {
      transition: 0.5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
      cursor: pointer;
      height: 100%;
      width: 100%;
      display: flex !important;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.6);

      span {
        margin-left: 0.5rem;
        font-family: 'Baloo Da 2';
        font-size: 1.4rem;
        color: var(--text-secondary);
      }

      .img {
        height: 1.4rem;
      }
    }

    .img-cont:hover .image {
      opacity: 0.3;
    }

    .img-cont:hover .middle {
      opacity: 1;
    }

    .text {
      background-color: #4caf50;
      color: white;
      font-size: 16px;
      padding: 16px 32px;
    }

    .disable-edit {
      border: none !important;
      box-shadow: none !important;
      background-color: white !important;
      padding-left: 0 !important;
    }

    .custom-file-input {
      height: 100%;
      position: absolute;
    }

    .custom-file-input::-webkit-file-upload-button {
      visibility: hidden;
    }
  }

  &__hero {
    background: #eff0f5;
    height: calc(100vh - 35rem);
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__details {
    margin-top: 2rem;
    width: 100%;
    .form {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .create-cont {
      width: 80%;
    }

    .label {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #75798a;
    }
  }

  &__form {
    width: 100%;
    margin-bottom: 2rem;

    &--wrapper {
      display: flex;

      .v-create__form:first-child {
        margin-right: 2rem;
      }
    }

    .summary {
      min-height: 11rem;
      max-height: 11rem;
    }

    &--1 {
      flex: 3;
    }

    &--2 {
      flex: 1;

      .form-control {
        &::placeholder {
          font-size: 1.2rem;
          color: var(--text-ternary);
        }
      }

      .get-address-btn {
        height: 4rem;
        width: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;
        border: 0.3rem solid var(--bg-1);
        color: var(--text-secondary);
        margin-left: 1rem;
        padding: 1rem;
        border-radius: 2rem;
        cursor: pointer;
      }
    }
  }

  .form-control {
    height: 4rem;
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #424557;
    text-align: left;
    width: 80%;
    margin: 2rem 0;
  }

  &__footer {
    display: flex;
    justify-content: flex-start;
  }

  &__cancel {
    margin-right: 2rem;
    background-color: white !important;
    box-shadow: none !important;
    color: #565a6c !important;
    border: none;

    &:active,
    &:focus,
    &:hover {
      background-color: white !important;
      color: #565a6c !important;
      border: none;
    }
  }

  &__create {
    background-color: #52f488 !important;
    color: #565a6c;
    border: 0.1rem solid transparent;
    box-shadow: none !important;
    padding: 1.5rem 1rem;

    &:disabled {
      border: none;
      cursor: not-allowed;
      color: #565a6c !important;
    }
  }
}

.map-cont {
  height: 28.5rem;
  width: 100%;
  margin-left: 2rem;
  margin-top: 3rem;
}

.place-address-cont {
  position: relative;
}

.name-form-group {
  position: relative;
}

.autocomplete-dropdown-container {
  position: absolute;
  background-color: white;
  z-index: 9999999999;
  // top: 100%;
  font-family: 'Baloo Da 2';
  color: var(--text-secondary);
  font-size: 1.4rem;
  padding: 1rem;
  min-height: 15rem;
  max-height: 20rem;
  overflow: auto;
  border: solid 2px #e1e2ea;
  width: 100%;

  .item {
    cursor: pointer;
    margin: 1rem 0;

    &:hover {
      color: var(--light-magenta);
    }
  }
}

.location-wrapper {
  display: flex;

  .name-form-group {
    flex: 2;
  }

  .map-cont {
    flex: 2;
    margin-left: 2rem;
  }
}
