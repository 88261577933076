@import '../../../sass/abstracts/mixins';

.causes {
  display: flex;
  justify-content: center;

  .cause-container {
    background-color: var(--bg-4);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0 3rem 0;
    width: 100%;

    .heading-cause {
      margin-bottom: 2.4rem;
      width: 89%;
      justify-content: space-between;
      display: flex;
      align-items: flex-end;
      padding-right: 3rem;

      @include breakpoint(mobileonly) {
        width: 100%;
        padding: 0 1rem;
      }

      @include breakpoint(phablet) {
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;
      }

      .title {
        font-size: 2.8rem;
        color: var(--text-primary);
        font-family: 'Baloo Da 2';
        font-weight: 500;
        position: relative;
        display: flex;
        align-items: flex-end;

        @include breakpoint(mobileonly) {
          font-size: 2rem;
        }

        span {
          z-index: 2;
        }
      }

      .shape-icon {
        height: 6.33rem;
        z-index: 1;
        margin-left: -4rem;

        @include breakpoint(mobileonly) {
          left: 60%;
          height: 4.33rem;
        }
      }
    }

    .cards-container {
      display: flex;
      flex-wrap: wrap;
      width: 92%;
      padding-left: 0.3rem;
      // justify-content: space-between;

      @include breakpoint(mobileonly) {
        width: 100%;
        align-items: center;
        justify-content: center;
      }

      @include breakpoint(phablet) {
        width: 100%;
        align-items: center;
        justify-content: center;
      }

      .empty-cards {
        width: 23rem;

        @include breakpoint(mobileonly) {
          display: none;
        }

        @include breakpoint(phablet) {
          display: none;
        }
      }

      .cards {
        object-fit: contain;
        margin: 2rem 0;
        padding: 1rem 1rem 3rem 1rem;
        border-radius: 0.3rem;
        width: 25rem;
        height: 31.33rem;

        @include breakpoint(mobileonly) {
          width: 25rem;
          margin: 1rem 0;
        }

        @include breakpoint(phablet) {
          width: 25rem;
          margin: 1rem 0;
        }

        .img-container {
          width: 100%;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          width: 100%;
          height: 180px;
          object-fit: contain;
          border-radius: 4px;
        }

        .cause-info {
          padding: 1rem;
          font-family: 'Baloo Da 2';

          .cause-title {
            font-size: 1.8rem;
            font-weight: bold;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .organization {
            font-size: 1.2rem;
            color: var(--text-secondary);
            margin-top: 0.5rem;
          }

          .description {
            font-size: 1.4rem;
            margin-top: 0.5rem;
            color: var(--text-secondary);
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -moz-box;
            -moz-line-clamp: 3;
            -moz-box-orient: vertical;
            height: 4rem;
          }

          .funds-progress {
            margin-top: 1.5rem;

            @include breakpoint(mobileonly) {
              display: flex;
            }

            .progress {
              height: 0.6rem;
              border-radius: 1.2rem;
              background-color: var(--bg-5);
              width: 100%;
            }

            .progress-bar {
              background: var(--vivid-green);
            }
          }
        }
      }

      .cards:hover {
        background-color: white;
        padding: 1rem 1rem 3rem 1rem;
        border-radius: 12px;
        transition: 0.3s all;
        box-shadow: 0 8px 24px 0 rgba(31, 34, 51, 0.16);

        .cause-title {
          color: var(--light-magenta);
        }
      }
    }

    .see-more {
      font-size: 1.4rem;
      font-family: 'Baloo Da 2';
      display: flex;
      align-items: flex-start;
      cursor: pointer;
      font-weight: bold;

      i {
        font-size: 1.6rem;
        margin-left: 0.8rem;

        @include breakpoint(mobileonly) {
          margin-bottom: 0.5rem;
        }

        @include breakpoint(phablet) {
          margin-bottom: 0.5rem;
        }
      }

      &:hover {
        color: var(--light-magenta) !important;
      }

      @include breakpoint(mobileonly) {
        font-size: 1.2rem;
      }

      @include breakpoint(phablet) {
        font-size: 1.2rem;
      }
    }
  }
}

//Cause card component
.cause-card {
  object-fit: contain;
  padding: 1rem 1rem 2rem 1rem;
  border-radius: 0.3rem;
  width: calc((100% - 10rem) / 4);
  height: auto;
  cursor: pointer;
  transition: 0.3s all;
  margin-right: 2.5rem;

  &:hover {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 16px 32px 0 rgba(31, 34, 51, 0.16),
      0 8px 16px 0 rgba(31, 34, 51, 0.08);

    .cause-card__title {
      color: var(--light-magenta);
    }
  }

  @include breakpoint(mobileonly) {
    width: calc((100% - 5rem) / 1);
    margin-right: 0;
  }

  @include breakpoint(phablet) {
    width: calc((100% - 5rem) / 2);
    margin-right: 1.5rem;
  }

  &__img {
    width: 100%;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 105px;
    object-fit: contain;
    border-radius: 4px;

    .overlay {
      position: relative;
      height: 100%;
      width: 100%;
    }
    .logo {
      height: 6rem;
      width: 6rem;
      position: absolute;
      top: 55%;
      left: 3%;
      box-shadow: 1px 1px 0.2rem 0.1rem rgba(0, 0, 0, 0.5);
      border-radius: 0.3rem;
    }
  }

  &__info {
    padding: 1rem;
    font-family: 'Baloo Da 2', 'sans-serif';
  }

  &__title {
    font-size: 1.8rem;
    font-weight: bold;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__organization {
    font-size: 1.2rem;
    color: var(--text-secondary);
    margin-top: 2.5rem;
  }

  &__description {
    font-size: 1.4rem;
    margin-top: 0.5rem;
    color: var(--text-secondary);
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -moz-box;
    -moz-line-clamp: 3;
    -moz-box-orient: vertical;
    height: 4rem;
  }

  &__funds-progress {
    margin-top: 1.5rem;

    @include breakpoint(mobileonly) {
      display: flex;
    }

    .progress {
      height: 0.6rem;
      border-radius: 1.2rem;
      background-color: var(--bg-5);
      width: 100%;
    }

    .progress-bar {
      background: var(--vivid-green);
    }
  }
}

.all-causes {
  padding-top: 6.6rem;
  color: var(--text-primary);
  font-family: 'Baloo Da 2', 'sans-serif';
  padding-bottom: 3rem;
  @include largeScreen();

  &__header {
    margin-bottom: 2rem;
    height: 14rem;
    padding: 2rem 5rem;
    // background-color: #eff0f5;
    z-index: 9;
    width: 100%;

    @include breakpoint(mobileonly) {
      padding: 2rem 1rem;
    }

    @include breakpoint(phablet) {
      padding: 2rem 1rem;
    }

    &--title {
      font-size: 2rem;
      width: 12rem;
      margin-bottom: 1rem;
      padding: 0 0 0.5rem 0;
    }
  }

  &__body {
    display: flex;
    margin: 0 5.5rem;

    @include breakpoint(mobileonly) {
      flex-direction: column;
      margin: 0;
    }
  }

  &__filter {
    flex: 1;

    @include breakpoint(mobileonly) {
      display: block;
    }
  }

  &__content {
    flex: 4;
    display: flex;
    flex-flow: row wrap;

    &:after {
      flex: auto;
    }

    @include breakpoint(mobileonly) {
      justify-content: center;
    }

    .cause-card {
      width: calc((100% - 10rem) / 4);
      height: 28rem;
      overflow: hidden;
      border-radius: 0.6rem;
      border: solid 2px #eff0f5 !important;
      cursor: pointer;
      transition: all 0.2s linear;
      margin-bottom: 2rem;
      margin-right: 2.5rem;

      @include breakpoint(mobileonly) {
        width: calc((100% - 2rem) / 1);
        margin-right: 0;
      }

      @include breakpoint(phablet) {
        width: calc((100% - 2rem) / 1);
        margin-right: 0;
      }

      &:hover {
        box-shadow: 0 16px 32px 0 rgba(31, 34, 51, 0.16),
          0 8px 16px 0 rgba(31, 34, 51, 0.08);
      }
    }
  }

  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem 0;
    width: 100%;
    &--image {
      width: 40rem;
    }
  }
}

.filter {
  &__title {
    font-size: 1.2rem;
    font-weight: 900;
    color: var(--text-secondary);
    margin-bottom: 1rem;
  }

  &__list {
    margin-bottom: 2rem;
  }

  &__list-item {
    font-size: 1.2rem;
    color: var(--text-secondary);
    margin: 0.6rem 0;
    cursor: pointer;

    &:hover {
      color: var(--highlight-primary);
    }

    &--active {
      color: var(--highlight-primary);
    }
  }
}
