@import "../../../../sass/abstracts/mixins";


.part-2 {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background-color: #323649;
  }
}

.part-1-1 {
  width: 50%;
  background-image: url("../../../../images/Auth/helpinghand.jpg");
  background-size: cover;
  height: auto;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  padding: 1rem 0;

  @include breakpoint(mobileonly) {
    display: none;
  }

  @include breakpoint(phablet) {
    display: none;
    height: auto;
  }
}

.sub-title {
  width: 100% !important;

  @include breakpoint(mobileonly) {
    width: 100% !important;
  }

  @include breakpoint(phablet) {
    width: 100% !important;
  }
}

.registerAddressCont {
  margin-top: 2rem;
  width: 100%;
  border: .2rem solid var(--bg-3);
  display: flex;
  flex-direction: column;
  padding: 1rem;

  .title {
    font-family: "Baloo Da 2";
    font-size: 1.2rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--text-secondary);
  }

  .address {
    font-family: "Baloo Da 2";
    font-size: 1.2rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: var(--text-secondary);
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include breakpoint(mobileonly) {
      width: 100%;
    }

    @include breakpoint(phablet) {
      width: 100%;
    }

    span {
      width: 80%;
    }
  }

}

.address-cont {
  margin-top: 2rem;
  .name-form-group {
    margin: 2rem 0;
    position: relative;
    label {
      font-family: "Baloo Da 2";
      font-size: 1.2rem;
      color: var(--text-secondary);
    }
  }

  .get-address-btn {
    height: 4rem;
    width: 12rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    border: 0.3rem solid var(--bg-1);
    color: var(--text-secondary);
    margin-left: 1rem;
    padding: 1rem;
    border-radius: 2rem;
    cursor: pointer;
  }

  .map-cont {
    height: 15rem;
    margin-left: 0 !important;
  }

  .location-search-input:focus {
    color: var(--text-secondary) !important;
    background-color: #fff;
    border-color: #fff !important;
    outline: 0;
    box-shadow: 0 0 0 0.16rem var(--light-magenta) !important;
  }
  
  .location-search-input {
    font-size: 1.4rem !important;
    height: 3rem;
    border-radius: 0.3rem;
    border: solid 0.03rem var(--text-ternary);
    font-family: "Baloo Da 2";
    color: var(--text-secondary);
  }

  .autocomplete-dropdown-container {
    position: absolute;
    background-color: white;
    z-index: 9999999999;
    top: 100%;
    width: 100%;
    font-family: "Baloo Da 2";
    color: var(--text-secondary);
    font-size: 1.4rem;
    padding: 1rem;
    min-height: 15rem;
    max-height: 20rem;
    overflow: auto;
    border: solid 0.03rem var(--text-ternary);

    .item {
      cursor: pointer;
      margin: 1rem 0;

      &:hover {
        color: var(--light-magenta);
      }
    }
  }

  .address {
    font-family: "Baloo Da 2";
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: var(--text-secondary);
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    border: solid 0.03rem var(--text-ternary);
    padding: 1rem;

    @include breakpoint(mobileonly) {
      width: 100%;
    }

    @include breakpoint(phablet) {
      width: 100%;
    }

    span {
      width: 80%;
    }

    .edit-btn {
      cursor: pointer;
      width: 4rem;
      font-family: "Baloo Da 2";
      font-size: 1.2rem;
      color: #ffffff;
      font-weight: bold;
      height: 2rem;
      margin: 0.5rem 0;
      display: flex;
      justify-content: space-around;
    }
  }
}

.btn {
  width: 10rem;
  font-family: "Baloo Da 2";
  font-size: 1.4rem;
  color: #ffffff;
  font-weight: bold;
  margin: 2rem 0;
}

.form-conts {
  .edit-form-group {
    margin-bottom: 1.4rem;
    label {
      font-family: "Baloo Da 2";
      font-size: 1.2rem;
      color: var(--text-secondary);
    }
  }
}
